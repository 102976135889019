import React from "react";
import CertificateImage from "../../../assets/pcb-assets/Certificate_5.png";
import StaticBanner from "../homepage/sections/StaticBanner";

const CertificatePage = () => {
  return (
    <div className="homepage-static-banner">
      <img
        src={CertificateImage}
        alt="static banner"
        className={
          window?.innerWidth > 767 ? "desktop-banner" : "mobile-banner"
        }
      />
      <div style={{ height: "17vh" }}></div>
    </div>
  );
};

export default CertificatePage;
