// import { useState } from "react";
import TextField from "@material-ui/core/TextField";

export default function CustomizeForm({
  customerName,
  jerseyNumber,
  updateState,
}) {
  // const [CustomName, setCustomName] = useState("");
  // const [CustomNumber, setCustomNumber] = useState("");

  const handleNumberInputChange = (event) => {
    const inputValue = event.target.value;

    if (/^\d{0,2}$/.test(inputValue)) {
      // Call the updateState function to update the parent's state
      updateState(customerName, inputValue);
    }
  };

  const handleAlphabetInputChange = (event) => {
    const inputValue = event.target.value;

    if (/^[a-zA-Z]{0,10}$/.test(inputValue)) {
      // Call the updateState function to update the parent's state
      updateState(inputValue, jerseyNumber);
    }
  };

  return (
    <div>
      <p style={{ fontSize: "1rem", color: "black" }}>
        Please Enter your Jersey Details Here
      </p>

      <div
        style={{
          gap: "20px",
          display: "flex",
          flexWrap: "wrap",
          margin: "10px 0px",
        }}
      >
        <TextField
          size="small"
          label="Name"
          variant="outlined"
          value={customerName}
          onChange={handleAlphabetInputChange}
          type="text"
          inputProps={{
            maxLength: 10, // Limit the input to 10 characters
            pattern: "[a-zA-Z]*", // Only allow alphabetic input
          }}
        />

        <TextField
          size="small"
          variant="outlined"
          label="Jersey Number"
          value={jerseyNumber}
          onChange={handleNumberInputChange}
          type="text"
          inputProps={{
            maxLength: 2, // Limit the input to 2 characters
            pattern: "[0-9]*", // Only allow numeric input
          }}
        />
      </div>

      <div
        style={{
          fontSize: "12px",
          padding: "0px 15px",
          color: "rgba(0, 0, 0, 0.85)",
        }}
      >
        <ul
          style={{
            padding: "0px",
          }}
        >
          <li>Only 10 character are allowed for Name.</li>
          <li>Only 2 digit Jersey Number is allowed.</li>
        </ul>
      </div>
    </div>
  );
}
