// Library Imports
import { useEffect } from "react";
import { Col, Row, Typography } from "antd";
// import ReactFlagsSelect from "react-flags-select";
// import { useDispatch, useSelector } from "react-redux";

// ⬇ Redux Reducers

// import {
//   changeCountry,
//   changeCountryCode,
//   changeCurrency,
// } from "../../../redux/Slices/multiLocationSlice";
// import { clearCart } from "../../../redux/Slices/cartSlice";
// import { clearWishList } from "../../../redux/Slices/wishListSlice";

// Assets, Media & StyleSheets 🚀

// import "./AnnouncementBar.scss";

// Library Constants

const { Text } = Typography;

function AnnouncementBar({ data }) {
  //   debugger;
  //   console.log("Announcement Props", data);

  //   const dispatch = useDispatch();

  // ⬇ Redux States

  //   const selectedCountry = useSelector(
  //     (state) => state?.multiLocationSlice?.defaultCountryCode
  //   );

  //   const countriesList = useSelector((state) =>
  //     state?.multiLocationSlice?.countriesList?.map((country) => country)
  //   );

  //   const countries = useSelector((state) =>
  //     state?.multiLocationSlice?.countriesList?.map(
  //       (country) => country?.short_code
  //     )
  //   );

  //   const location = (code) => {
  //     // debugger;
  //     let country = countriesList?.find(
  //       (country) => country?.short_code === code
  //     );

  //     if (selectedCountry !== country?.short_code) {
  //       dispatch(changeCountry(country?.country_name));
  //       dispatch(changeCountryCode(country?.short_code));
  //       dispatch(changeCurrency(country?.currency));
  //       dispatch(clearCart());
  //       dispatch(clearWishList());
  //     }
  //   };

  //   useEffect(() => {}, [props, selectedCountry]);
  useEffect(() => {}, [data]);

  return (
    <Row
      align="middle"
      justify="center"
      className="announcement_bar"
      style={{
        background: data?.announcement_bar?.background_color
          ? data?.announcement_bar?.background_color
          : "#06351f",
        // color: "white",
        padding: "10px",
        // fontSize: "12px",
        textAlign: "center",
        borderTop: "3px solid #8f7223",
      }}
    >
      {/* <Col xs={{ span: 0 }} lg={{ span: 3 }} style={{ textAlign: "start" }}>
        <Text>{data?.announcement_bar?.phone_number} </Text>
      </Col> */}

      {/* <Col xs={{ span: 24 }} lg={{ span: 18 }} style={{ textAlign: "center" }}> */}
      <Col span={24} style={{ textAlign: "center" }}>
        <Text
          style={{
            color: "white",
            fontWeight: 500,
            fontSize: "12px",
            textAlign: "center",
          }}
        >
          {data?.announcement_bar?.announcement_text}
        </Text>
      </Col>

      {/* <Col xs={{ span: 0 }} lg={{ span: 3 }} style={{ textAlign: "end" }}>
        <ReactFlagsSelect
          className="react_flag_select"
          selected={selectedCountry}
          countries={countries}
          onSelect={(code) => location(code)}
          fullWidth={false}
        />
      </Col> */}
    </Row>
  );
}

export default AnnouncementBar;
